<template>
  <div class="scoped_cover_div">
    <div class="jobs_table sp2">
    <div style="background-color: #004a7c;width: 100%;padding: 15px;display: flex;">
      <label for="search" style="color:#ccc;margin-left: 80%;"> Search:&nbsp;&nbsp;&nbsp;
        <input type="text" style="padding:5px;width:150px;" v-model="searchText">
      </label>
    </div>
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
        <table>
          <tr>
            <th>Service Name</th>
            <th>Service Code</th>
            <th>Weight Limit</th>
            <th>Priority</th>
            <th>Action</th>
          </tr>

          <tr v-for="(service, i) in carrierList" :key="i">
            <td>
              <ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper">
                  <input type="text" placeholder="Enter Character Limit 30" :maxlength="max"
                    :class="errors[0] ? 'warning-border' : ''" v-model="service.name"
                    :disabled="!service.is_edit_mode" />
                  <!-- 
                  <select v-model="service.type" :disabled="!service.is_edit_mode":class="errors[0] ? 'warning-border':''">
                  <option value>Select</option>
                  <option value="D">Shift</option>
                  <option value="I">International</option>
                  <option value="M">Messenger</option>
                  <option value="T">Transport</option>
                </select> -->
                </div>
              </ValidationProvider>
            </td>
            <td>
              <ValidationProvider name="code" rules="required|max:30" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper">
                  <input type="text" placeholder="Enter Character Limit 30" :maxlength="max"
                    :class="errors[0] ? 'warning-border' : ''" v-model="service.code"
                    :disabled="!service.is_edit_mode" />
                </div>
              </ValidationProvider>
            </td>

            <td>
              <ValidationProvider name="weight limit" rules="required|max:30" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper">
                  <!--  <select v-model="service.weight_limit" :disabled="!service.is_edit_mode">
                 <option value="" disabled>Select Weight Limit</option>
                    <option value = "1">1 KG</option>
                    <option value = "5">5 KG</option> 
                    <option value = "10">10 KG</option>
                    <option value = "20">20 KG</option>
                    <option value = "No Limit">No Limit</option>
              </select> -->

                  <input type="text" placeholder="Enter Character Limit 30" :maxlength="max"
                    :class="errors[0] ? 'warning-border' : ''" v-model="service.weight_limit"
                    :disabled="!service.is_edit_mode" />
                </div>
              </ValidationProvider>
            </td>

            <td>
              <ValidationProvider name="priority" rules="required|max:30" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper">
                  <select v-model="service.priority" :disabled="!service.is_edit_mode">
                    <option value="Standard">Standard</option>
                    <option value="Express">Express</option>
                    <option value="Immediate">Immediate</option>
                  </select>
                </div>
              </ValidationProvider>
              <div class="label_and_element_wrapper">
                <label class="label-radio chk_label">
                  <span>Document</span>
                  <input type="checkbox" class="chk_prefered" name="type_of_goods_document"
                    v-model="service.type_of_goods_document" :disabled="!service.is_edit_mode" true-value="1"
                    false-value="0">
                </label>
                <label class="label-radio chk_label">
                  <span>Parcel</span>
                  <input type="checkbox" class="chk_prefered" name="type_of_goods_parcel"
                    v-model="service.type_of_goods_parcel" :disabled="!service.is_edit_mode" true-value="1"
                    false-value="0">
                </label>
              </div>
            </td>

            <td class>
              <a class="table_action_btn" title="DELETE" @click="deleteService(service.id)">DELETE</a>
              <a class="table_action_btn" title="CANCEL" v-if="service.is_edit_mode" @click="cancelService()">CANCEL</a>
              <a class="table_action_btn" title="UPDATE" v-if="service.is_edit_mode"
                @click="updateService(service)">UPDATE</a>
              <a class="table_action_btn" title="EDIT" v-if="!service.is_edit_mode"
                @click="service.is_edit_mode = true">EDIT</a>
            </td>
          </tr>
        </table>
      </ValidationObserver>
    </div>
  </div>
</template>
<script>
export default {
  name: "CarrierServices",
  components: {},
  data() {
    return {
      max: 30,
      searchText:'',
      services: [],
      carriers: [],
    };
  },
  computed:{
    carrierList() {
      return this.services.filter((service) => {
        return this.searchText.toLowerCase().split(' ').every(v => service.code.toLowerCase().includes(v)) ||
          this.searchText.toLowerCase().split(' ').every(v => service.name.toLowerCase().includes(v))

      })
    }
  },
  created() {
    this.fetchServices();
  },
  methods: {
    fetchServices() {
      this.axios
        .get("/api/service")
        .then((response) => {
          this.services = response.data.services.map((element) => {
            element.is_edit_mode = false;
            element.type_of_goods_document = element.type_of_goods == 1 || element.type_of_goods == 3 ? 1 : 0;
            element.type_of_goods_parcel = element.type_of_goods == 2 || element.type_of_goods == 3 ? 1 : 0;
            return element;
          });
          console.log(this.services);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    updateService(data) {
      this.$refs.observer.validate();
      this.axios
        .put("/api/service/" + data.id, data)
        .then((response) => {
          this.toast.success(response.data.msg);
          data.is_edit_mode = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteService(id) {
      if (confirm("Are you sure you want to delete this Carrier Services?")) {
        this.axios
          .delete("/api/service/" + id)
          .then((response) => {
            this.toast.success(response.data.msg);
            this.fetchServices();
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    cancelService() {
      this.fetchServices();
    },
  },
};
</script>
<style scoped>
.scoped_cover_div {
  margin: 0;
}

.scoped_cover_div section {
  padding: 0;
}

.scoped_cover_div .form_section1 {
  margin-top: 0;
}

.jobs_table.sp2 table tr td {
  padding: 5px 2px;
  vertical-align: top;
}

.jobs_table.sp2 table tr th {
  padding: 5px 10px;
  height: 50px;
  width: 25%;
}

.table_action_btn {
  width: 80px;
  height: 40px;
  line-height: 40px;
  text-align-last: center;
  background: #06a5ed;
  color: #000;
  font-weight: 700;
  display: inline-block;
  margin: 2px;
}

.width_40px {
  width: 80px;
}

.righted {
  text-align: right;
  width: auto;
}

.label_and_element_wrapper input[type="text"] {
  padding: 5px 10px;
}

.user_profile_upload {
  display: block;
  border: 0;
  height: 40px;
  width: 150px;
  background: #004a7c;
  color: #fff;
  font-size: 18px;
  margin-right: 10px;
  position: relative;
}

.user_profile_upload input {
  opacity: 0;
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
}

.user_profile_upload span {
  display: block;
  width: 100%;
  height: 40px;
  text-align: center;
  line-height: 30px;
  text-align: center;
  color: #000;
  font-weight: normal;
}

.label_and_element_wrapper img {
  display: block;
  width: 150px;
  height: auto;
}

.label-radio {
  display: inline-block;
}

.label-radio span {
  color: #333;
}

.chk_label {
  display: inline-block;
  margin: 0px 10px 0px 0px;
  background: #fff;
  height: 40px;
  border: 1px solid #ccc;
  width: 50%;
}

.chk_label:nth-child(2) {
  width: 47%;
  margin-right: 0;
}

.chk_label span {
  display: inline-block;
  position: relative;
  padding: 0 0 0 20px;
  top: 0px;
}

.chk_label input {
  display: inline-block;
  position: relative;
  top: 5px;
}
</style>
